import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                pageTitle: 'Facilitystore - Optify',
                breadcrumb: [
                    {
                        text: 'Overblik',
                        active: true,
                    },
                ],
                resource: 'Client',
                action: 'read',
            },
        },

        {
            path: '/products',
            name: 'products',
            component: () => import('@/views/products/Products.vue'),
            meta: {
                pageTitle: 'Produkter',
                breadcrumb: [
                    {
                        text: 'Produkter',
                        active: true,
                    },
                ],
                resource: 'Product',
                action: 'manage',
            },
        },
        {
            path: '/products/:id',
            name: 'product',
            component: () => import('@/views/products/Product.vue'),
            meta: {
                pageTitle: 'Produkt',
                breadcrumb: [
                    {
                        text: 'Produkter',
                        to: "/products",
                        active: false,
                    },
                    {
                        text: 'Produkt',
                        active: true,
                    },
                ],
                resource: 'Product',
                action: 'manage',
            },
        },

        {
            path: '/history',
            name: 'history',
            component: () => import('@/views/history/History.vue'),
            meta: {
                pageTitle: 'Historik',
                breadcrumb: [
                    {
                        text: 'Historik',
                        active: true,
                    },
                ],
                resource: 'Product',
                action: 'read',
            },
        },

        {
            path: '/categories',
            name: 'categories',
            component: () => import('@/views/categories/Categories.vue'),
            meta: {
                pageTitle: 'Kategorier',
                breadcrumb: [
                    {
                        text: 'Kategorier',
                        active: true,
                    },
                ],
                resource: 'Category',
                action: 'read',
            },
        },
        {
            path: '/categories/:id',
            name: 'category',
            component: () => import('@/views/categories/Category.vue'),
            meta: {
                pageTitle: 'Kategori',
                breadcrumb: [
                    {
                        text: 'Kategorier',
                        to: "/categories",
                        active: false,
                    },
                    {
                        text: 'Kategori',
                        active: true,
                    },
                ],
                resource: 'Category',
                action: 'read',
            },
        },

        {
            path: '/brands',
            name: 'brands',
            component: () => import('@/views/brands/Brands.vue'),
            meta: {
                pageTitle: 'Producenter',
                breadcrumb: [
                    {
                        text: 'Producenter',
                        active: true,
                    },
                ],
                resource: 'Brand',
                action: 'read',
            },
        },
        {
            path: '/brands/:id',
            name: 'brand',
            component: () => import('@/views/brands/Brand.vue'),
            meta: {
                pageTitle: 'Producenter',
                breadcrumb: [
                    {
                        text: 'Producenter',
                        to: "/brands",
                        active: false,
                    },
                    {
                        text: 'Producent',
                        active: true,
                    },
                ],
                resource: 'Brand',
                action: 'read',
            },
        },

        {
            path: '/suppliers',
            name: 'suppliers',
            component: () => import('@/views/suppliers/Suppliers.vue'),
            meta: {
                pageTitle: 'Leverandører',
                breadcrumb: [
                    {
                        text: 'Leverandører',
                        active: true,
                    },
                ],
                resource: 'Supplier',
                action: 'read',
            },
        },
        {
            path: '/suppliers/:id',
            name: 'supplier',
            component: () => import('@/views/suppliers/Supplier.vue'),
            meta: {
                pageTitle: 'Leverandører',
                breadcrumb: [
                    {
                        text: 'Leverandører',
                        to: "/suppliers",
                        active: false,
                    },
                    {
                        text: 'Leverandør',
                        active: true,
                    },
                ],
                resource: 'Supplier',
                action: 'read',
            },
            children: [
                {
                    path: 'pricerules',
                    name: 'supplier-pricerules',
                },
                {
                    path: 'products',
                    name: 'supplier-products',
                }
            ]
        },




        {
            path: '/stores',
            name: 'stores',
            component: () => import('@/views/stores/Stores.vue'),
            meta: {
                pageTitle: 'Butikker',
                breadcrumb: [
                    {
                        text: 'Butikker',
                        active: true,
                    },
                ],
                resource: 'Store',
                action: 'read',
            },
        },
        {
            path: '/stores/:id',
            name: 'store',
            component: () => import('@/views/stores/Store.vue'),
            meta: {
                pageTitle: 'Butik',
                breadcrumb: [
                    {
                        text: 'Butikker',
                        to: "/stores",
                        active: false,
                    },
                    {
                        text: 'Butik',
                        active: true,
                    },
                ],
                resource: 'Store',
                action: 'read',
            },
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/users/Users.vue'),
            meta: {
                pageTitle: 'Brugere',
                breadcrumb: [
                    {
                        text: 'Brugere',
                        active: true,
                    },
                ],
                resource: 'Admin',
                action: 'manage',
            },
        },
        {
            path: '/users/:id',
            name: 'user',
            component: () => import('@/views/users/User.vue'),
            meta: {
                pageTitle: 'Bruger',
                breadcrumb: [
                    {
                        text: 'Brugere',
                        to: "/users",
                        active: false,
                    },
                    {
                        text: 'Bruger',
                        active: true,
                    },
                ],
                resource: 'Admin',
                action: 'manage',
            },
        },
        {
            path: '/modules',
            name: 'modules',
            component: () => import('@/views/Modules.vue'),
            meta: {
                pageTitle: 'Moduler',
                breadcrumb: [
                    {
                        text: 'Moduler',
                        active: true,
                    },
                ],
                resource: 'Admin',
                action: 'manage',
            },
        },



        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'

router.beforeEach(async (to, _, next) => {
    store.dispatch("app/UPDATE_PAGETITLE", null)

    if (!store.state.userInit) {
        await store.dispatch("INIT_USER")
    }

    var isLoggedIn = store.state.user

    if (isLoggedIn && !store.state.stores) {
        await store.dispatch("STORES")
        await store.dispatch("USERS")
    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        // ! We updated login route name here from `auth-login` to `login` in starter-kit
        if (!isLoggedIn) return next({ name: 'login' })

        // If logged in => not authorized
        return next({ name: 'home' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: 'home' })
    }

    return next()
})

const DEFAULT_TITLE = 'Facilitystore - Optify'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.pageTitle || DEFAULT_TITLE
    })
})


export default router
